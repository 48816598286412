<template>
    <div>
        <p>
        <el-button type="info" size="small" @click="notifyPause">Pause Notify</el-button>
        </p>
        <h3>Subscriber <el-button type="info" size="mini" @click="subscriberEdit({})">New</el-button></h3>
         <el-table
                ref="subscriberTable"
                border
                stripe
                :data="subscribers"
                :row-key="rowKey"
                :expand-row-keys="subscriberExpandKeys"
                @row-click="subscriberRowClick">
            <el-table-column type="expand">
                <template slot-scope="scope">
                    <log-follow :name="scope.row.logfile"></log-follow>
                </template>
            </el-table-column>
            <el-table-column prop="info.name" label="name"></el-table-column>
            <el-table-column prop="info.provider" label="provider" width="100px"></el-table-column>
            <el-table-column prop="status" label="status" width="80px"></el-table-column>
            <el-table-column label="action">
                <template slot-scope="scope">
                    <el-button size="mini" type="info" @click.stop="subscriberEdit(scope.row.info)">Edit</el-button>
                </template>
            </el-table-column>
        </el-table>
        <h3>Account <el-button type="info" size="mini" @click="accountEdit({})">New</el-button></h3>
        <el-table
                ref="accountTable"
                border
                stripe
                :data="accounts"
                :row-key="rowKey"
                :expand-row-keys="accountExpandKeys"
                @row-click="accountRowClick">
            <el-table-column type="expand">
                <template slot-scope="scope">
                    <log-follow :name="scope.row.logfile"></log-follow>
                </template>
            </el-table-column>
            <el-table-column prop="info.name" label="name"></el-table-column>
            <el-table-column prop="info.provider" label="provider" width="100px"></el-table-column>
            <el-table-column label="enable" width="80px">
                <template slot-scope="scope">
                    <el-tag v-if="scope.row.info.enabled" type="success">True</el-tag>
                    <el-tag v-else type="warning">False</el-tag>
                </template>
            </el-table-column>
            <el-table-column label="daily" width="150px">
                <template slot-scope="scope">
                    {{scope.row.info.daily_start_time}} - {{scope.row.info.daily_stop_time}}
                </template>
            </el-table-column>
            <el-table-column prop="status" label="status" width="80px"></el-table-column>
            <el-table-column label="action">
                <template slot-scope="scope">
                    <el-button size="mini" type="info" @click.stop="accountEdit(scope.row.info)">Edit</el-button>
                    <el-button v-if="scope.row.status === 'dead' || scope.row.status === 'login'" type="success" size="mini" @click.stop="accountLogin(scope.row.info.id)">Login</el-button>
                    <el-button v-if="scope.row.status !== 'dead' && scope.row.status !== 'error'" type="danger" size="mini" @click.stop="accountShutdown(scope.row.info.id)">Shutdown</el-button>
                </template>
            </el-table-column>
        </el-table>
        <h3>Task <el-button type="info" size="mini" @click="taskEdit({})">New</el-button></h3>
        <el-table
                ref="taskTable"
                border
                stripe
                :data="tasks"
                :row-key="rowKey"
                :expand-row-keys="taskExpandKeys"
                @row-click="taskRowClick">
            <el-table-column type="expand">
                <template slot-scope="scope">
                    <log-follow :name="scope.row.info.name"></log-follow>
                </template>
            </el-table-column>
            <el-table-column prop="info.name" label="name"></el-table-column>
            <el-table-column prop="info.strategy" label="strategy" width="100px"></el-table-column>
            <el-table-column label="account" width="100px">
                <template slot-scope="scope">
                    {{accountsEnabledMap[scope.row.info.account_id]}}
                </template>
            </el-table-column>
            <el-table-column label="subscriber" width="100px">
                <template slot-scope="scope">
                    {{subscribersMap[scope.row.info.subscriber_id]}}
                </template>
            </el-table-column>
            <el-table-column label="enable" width="80px">
                <template slot-scope="scope">
                    <el-tag v-if="scope.row.info.enabled" type="success">True</el-tag>
                    <el-tag v-else type="warning">False</el-tag>
                </template>
            </el-table-column>
            <el-table-column label="daily" width="150px">
                <template slot-scope="scope" v-if="scope.row.info.account_id===0">
                    {{scope.row.info.daily_start_time}} - {{scope.row.info.daily_stop_time}}
                </template>
            </el-table-column>
            <el-table-column prop="status" label="status" width="80px"></el-table-column>
            <el-table-column label="action">
                <template slot-scope="scope">
                    <el-button size="mini" type="info" @click.stop="taskEdit(scope.row.info)">Edit</el-button>
                    <el-button v-if="scope.row.status === 'pause'" type="success" size="mini" @click.stop="taskStart(scope.row.info)">Start</el-button>
                    <el-button v-if="scope.row.status === 'running'" type="warning" size="mini" @click.stop="taskPause(scope.row.info)">Pause</el-button>
                    <el-button v-if="scope.row.status === 'dead'" type="success" size="mini" @click.stop="taskBoot(scope.row.info)">Boot</el-button>
                    <el-button v-if="scope.row.status !== 'dead' && scope.row.status !== 'error'" type="danger" size="mini" @click.stop="taskShutdown(scope.row.info)">Shutdown</el-button>
                </template>
            </el-table-column>
        </el-table>

        <el-dialog width="60%" :title="(form.subscriber.id > 0 ? 'Edit' : 'New') + ' Subscriber'" :visible.sync="showSubscriberForm">
            <el-form label-width="70px">
                <el-form-item label="Name">
                    <el-input v-model="form.subscriber.name"></el-input>
                </el-form-item>
                <el-form-item label="Provider">
                    <el-select v-model="form.subscriber.provider">
                        <el-option v-for="item in subscriberProviders" :key="item" :label="item" :value="item"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="Config">
                    <prism-editor class="editor" language="json" v-model="form.subscriber.config" line-numbers :highlight="highlighter"></prism-editor>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="subscriberSave">Save</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>

        <el-dialog width="60%" :title="(form.account.id > 0 ? 'Edit' : 'New') + ' Account'" :visible.sync="showAccountForm">
            <el-form label-width="70px">
                <el-form-item label="Name">
                    <el-input v-model="form.account.name"></el-input>
                </el-form-item>
                <el-form-item label="Provider">
                    <el-select v-model="form.account.provider">
                        <el-option v-for="item in traderProviders" :key="item" :label="item" :value="item"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="Enable">
                    <el-switch v-model="form.account.enabled"></el-switch>
                </el-form-item>
                <el-form-item label="Daily">
                    <el-time-picker v-model="form.account.daily_start_time" placeholder="boot time" value-format="HH:mm:ss"></el-time-picker> -
                    <el-time-picker v-model="form.account.daily_stop_time" placeholder="shutdown time" value-format="HH:mm:ss"></el-time-picker>
                </el-form-item>
                <el-form-item label="Config">
                    <prism-editor class="editor" language="json" v-model="form.account.config" line-numbers :highlight="highlighter"></prism-editor>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="accountSave">Save</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>

        <el-dialog width="60%" :title="(form.task.id > 0 ? 'Edit' : 'New') + ' Task'" :visible.sync="showTaskForm">
            <el-form label-width="80px">
                <el-form-item label="Name">
                    <el-input v-model="form.task.name"></el-input>
                </el-form-item>
                <el-form-item label="Strategy">
                    <el-select v-model="form.task.strategy">
                        <el-option v-for="item in strategies" :key="item" :label="item" :value="item"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="Account">
                    <el-select clearable v-model="form.task.account_id">
                        <el-option v-for="item in accountsEnabled" :key="item.id" :label="item.name" :value="item.id"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="Subscriber">
                    <el-select clearable v-model="form.task.subscriber_id">
                        <el-option v-for="item in subscribers" :key="item.info.id" :label="item.info.name" :value="item.info.id"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="Enable">
                    <el-switch v-model="form.task.enabled"></el-switch>
                </el-form-item>
                <el-form-item label="Daily">
                    <el-time-picker v-model="form.task.daily_start_time" placeholder="boot time" value-format="HH:mm:ss"></el-time-picker> -
                    <el-time-picker v-model="form.task.daily_stop_time" placeholder="shutdown time" value-format="HH:mm:ss"></el-time-picker>
                </el-form-item>
                <el-form-item label="Config">
                    <prism-editor class="editor" language="json" v-model="form.task.config" line-numbers :highlight="highlighter"></prism-editor>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="taskSave">Save</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>

        <el-dialog width="80%" :title="`Login ${accountLoginName}`" :visible.sync="showAccountLoginDialog">
            <el-form label-width="70px">
                <el-form-item label="Capture"><img :src="capture"></el-form-item>
                <el-form-item label="Code">
                    <el-input v-model="accountLoginCode" type="number"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" size="mini" @click="accountLoginCapture">Login</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
    </div>
</template>

<script>
import { PrismEditor } from 'vue-prism-editor'
import 'vue-prism-editor/dist/prismeditor.min.css';
import { highlight, languages } from 'prismjs/components/prism-core';
import 'prismjs/components/prism-json';
import 'prismjs/themes/prism-tomorrow.css';
import { Message } from 'element-ui'

import api from '@/common/api'
import LogFollow from '@/components/LogFollow'

export default {
    components: {
        PrismEditor,
        LogFollow
    },
    mounted() {
        this.loadBase()
        this.loadSubscribers()
        this.subscriberIntervalId = setInterval(this.loadSubscribers, 3000)
        this.loadAccounts()
        this.accountIntervalId = setInterval(this.loadAccounts, 3000)
        this.loadTasks()
        this.taskIntervalId = setInterval(this.loadTasks, 3000)
    },
    destroyed() {
        clearInterval(this.accountIntervalId)
        clearInterval(this.taskIntervalId)
        clearInterval(this.subscriberIntervalId)
    },
    methods: {
        highlighter(code) {
            return highlight(code, languages.json); // languages.<insert language> to return html with markup
        },
        loadBase() {
            api.get('/subscriber-provider').then(providers => {
                this.subscriberProviders = providers
            })
            api.get('/strategy').then(strategies => {
                this.strategies = strategies
            })
            api.get('/trader-provider').then(providers => {
                this.traderProviders = providers
            })
        },
        loadSubscribers() {
            api.get('/subscriber').then(subscribers => {
                this.subscribers = subscribers
                var subscribersMap = {0: ''}
                subscribers.forEach(item => {
                    subscribersMap[item.info.id] = item.info.name
                })
                this.subscribersMap = subscribersMap
            });
        },
        loadAccounts() {
            api.get('/account').then(accounts => {
                this.accounts = accounts
                var accountsEnabled = []
                var accountsEnabledMap = {0: ''}
                accounts.forEach(item => {
                    if (item.info.enabled) {
                        accountsEnabled.push({
                            id: item.info.id,
                            name: item.info.name
                        })
                        accountsEnabledMap[item.info.id] = item.info.name
                    }
                })
                this.accountsEnabled = accountsEnabled
                this.accountsEnabledMap = accountsEnabledMap
            });
        },
        loadTasks() {
            api.get('/task').then(tasks => {
                this.tasks = tasks
            })
        },
        accountLogin(id) {
            api.post(`/account/${id}/login`).then(() => {
                // if (!res.capture || res.capture.length === 0) {
                //     this.$message({message: "验证码为空", type: "error"})
                //     return
                // }
                // this.capture = res.capture
                // this.accountLoginName = name
                // this.accountLoginCode = null
                // this.showAccountLoginDialog = true
                this.accountFollow(id)
            })
        },
        accountLoginCapture() {
            api.post(`/account/${this.accountLoginName}/login-code`, {
                code: this.accountLoginCode
            }).then(() => {
                this.showAccountLoginDialog = false
                this.accountFollow(this.accountLoginName)
                this.accountLoginName = null
                this.capture = null
            })
        },
        rowKey(row) {
            return row.info.id
        },
        subscriberEdit(info) {
            this.form.subscriber = info
            if (info.config) {
                this.form.subscriber.config = JSON.stringify(JSON.parse(this.form.subscriber.config), null, 4)
            }
            this.showSubscriberForm = true
        },
        subscriberSave() {
            var form = {}
            Object.assign(form, this.form.subscriber)
            if (form.daily_start_time == '00:00:00') {
                form.daily_start_time == null
            }
            if (form.daily_stop_time == '00:00:00') {
                form.daily_stop_time == null
            }
            if (form.id > 0) {
                api.put(`/subscriber/${form.id}`, form).then(() => {
                    Message({message: "Success", type: 'success'})
                    this.subscriberFollow(form.id)
                    this.showSubscriberForm = false
                }).then(() => {
                    this.loadSubscribers()
                })
            } else {
                api.post(`/subscriber`, form).then(() => {
                    Message({message: "Success", type: 'success'})
                    this.showSubscriberForm = false
                }).then(() => {
                    this.loadSubscribers()
                })
            }
        },
        accountSave() {
            var form = {}
            Object.assign(form, this.form.account)
            if (form.daily_start_time == '00:00:00') {
                form.daily_start_time == null
            }
            if (form.daily_stop_time == '00:00:00') {
                form.daily_stop_time == null
            }
            if (form.id > 0) {
                api.put(`/account/${form.id}`, form).then(() => {
                    Message({message: "Success", type: 'success'})
                    this.accountFollow(form.id)
                    this.showAccountForm = false
                }).then(() => {
                    this.loadAccounts()
                })
            } else {
                api.post(`/account`, form).then(() => {
                    Message({message: "Success", type: 'success'})
                    this.showAccountForm = false
                }).then(() => {
                    this.loadAccounts()
                })
            }
        },
        taskSave() {
            var form = {}
            Object.assign(form, this.form.task)
            if (!form.account_id) {
                form.account_id = 0
            }
            if (!form.subscriber_id) {
                form.subscriber_id = 0
            }
            if (form.daily_start_time == '00:00:00') {
                form.daily_start_time == null
            }
            if (form.daily_stop_time == '00:00:00') {
                form.daily_stop_time == null
            }
            if (form.id > 0) {
                api.put(`/task/${form.id}`, form).then(() => {
                    Message({message: "Success", type: 'success'})
                    this.taskFollow(form.id)
                    this.showTaskForm = false
                }).then(() => {
                    this.loadTasks()
                })
            } else {
                api.post(`/task`, form).then(() => {
                    Message({message: "Success", type: 'success'})
                    this.showTaskForm = false
                }).then(() => {
                    this.loadTasks()
                })
            }
        },
        accountEdit(info) {
            this.form.account = info
            if (info.config) {
                this.form.account.config = JSON.stringify(JSON.parse(this.form.account.config), null, 4)
            }
            this.showAccountForm = true
        },
        taskEdit(info) {
            this.form.task = info
            if (info.account_id == 0) {
                this.form.task.account_id = null
            }
            if (info.subscriber_id == 0) {
                this.form.task.subscriber_id = null
            }
            if (info.config) {
                this.form.task.config = JSON.stringify(JSON.parse(this.form.task.config), null, 4)
            }
            this.showTaskForm = true
        },
        subscriberFollow(id) {
            if (this.$refs.subscriberTable.expandRowKeys.indexOf(id) < 0) {
                this.$refs.subscriberTable.toggleRowExpansion({info: {id: id}})
            }
        },
        accountFollow(id) {
            if (this.$refs.accountTable.expandRowKeys.indexOf(id) < 0) {
                this.$refs.accountTable.toggleRowExpansion({info: {id: id}})
            }
        },
        accountShutdown(id) {
            api.post(`/account/${id}/shutdown`).then(() => {
                this.accountFollow(id)
            })
        },
        accountRowClick(row) {
            this.$refs.accountTable.toggleRowExpansion(row)
        },
        subscriberRowClick(row) {
            this.$refs.subscriberTable.toggleRowExpansion(row)
        },
        taskFollow(id) {
            if (this.$refs.taskTable.expandRowKeys.indexOf(id) < 0) {
                this.$refs.taskTable.toggleRowExpansion({info: {id: id}})
            }
        },
        taskRowClick(row) {
            this.$refs.taskTable.toggleRowExpansion(row)
        },
        taskBoot(info) {
            api.post(`/task/${info.id}/boot`).then(() => {
                this.taskFollow(info.id)
            })
        },
        taskStart(info) {
            api.post(`/task/${this.name}/start`).then(() => {
                this.taskFollow(info.id)
            })
        },
        taskPause(info) {
            api.post(`/task/${info.id}/pause`).then(() => {
                this.taskFollow(info.id)
            })
        },
        taskShutdown(info) {
            api.post(`/task/${info.id}/shutdown`).then(() => {
                this.taskFollow(info.id)
            })
        },
        notifyPause() {
            api.post('/notify/pause').then(() => {
                Message({message: "Success", type: 'success'})
            })
        }
    },
    data() {
        return {
            form: {
                subscriber: {},
                task: {},
                account: {}
            },
            accountsEnabled: [],
            accountsEnabledMap: {},
            subscriberProviders: [],
            traderProviders: [],
            strategies: ["broodmother", "rubick", "rubick2", "sbr_ctrl", "storage", "hs300"],
            showSubscriberForm: false,
            showAccountForm: false,
            showTaskForm: false,
            subscriberExpandKeys: [],
            accountExpandKeys: [],
            taskExpandKeys: [],
            subscribers: [],
            subscribersMap: {},
            accounts: [],
            tasks: [],
            capture: null,
            accountLoginName: null,
            accountLoginCode: null,
            showAccountLoginDialog: false,
            subscriberIntervalId: null,
            accountIntervalId: null,
            taskIntervalId: null,
        }
    }
}
</script>

<style>
  /* required class */
  .editor {
    /* we dont use `language-` classes anymore so thats why we need to add background and text color manually */
    background: #2d2d2d;
    color: #ccc;

    /* you must provide font-family font-size line-height. Example: */
    font-size: 14px;
    line-height: 1.5;
    padding: 5px;
  }

  /* optional class for removing the outline */
  .prism-editor__textarea:focus {
    outline: none;
  }
</style>