<template>
    <div>
        <el-input type="textarea" ref="logView" rows="10" readonly v-model="content"></el-input>
    </div>
</template>

<script>
    import api from '@/common/api'
    export default {
        name: 'log-follow',
        props: {
            name: String
        },
        data() {
            return {
                offset: 0,
                content: '',
                intervalId: null
            }
        },
        updated() {
            this.$refs.logView.$el.children[0].scrollTop = this.$refs.logView.$el.children[0].scrollHeight
        },
        mounted() {
            this.followLog()
            this.intervalId = setInterval(this.followLog, 1000)
        },
        destroyed() {
            clearInterval(this.intervalId)
        },
        methods: {
            followLog() {
                api.get(`/logging/${this.name}?offset=${this.offset}`).then(res => {
                    this.offset = res.offset
                    this.content += res.content
                })
            }
        }
    }
</script>